<template>
  <div>
    <aw-cms-page
      v-for="{ content, idx } in filteredList"
      :key="content.id"
      :content-data="content"
      :force-full-width="forceFullWidth"
      is-nested
      :starting-promotion-index="previousLengthsSum[idx] || 0"
    />
  </div>
</template>

<script setup>
  import { computed, defineAsyncComponent } from 'vue';
  import * as ContentPageTypePositions from '~~/common/config/ContentPageTypePosition.js';
  const AwCmsPage = defineAsyncComponent(() => import('~~/common/components/Page/Content/AwCmsPage'));

  const props = defineProps({
    contentList: {
      type: Array,
      required: true,
    },
    contentListLengths: {
      type: Array,
      required: true,
    },
    contentPosition: {
      type: String,
      required: true,
      validator: value => Object.values(ContentPageTypePositions).includes(value),
    },
    forceFullWidth: {
      type: Boolean,
      default: false,
    },
  });
  const filteredList = computed(() => {
    return (props.contentList
      .map((content, idx) => {
        return {
          content,
          idx,
        };
      })
      .filter(({ content }) => {
        return content.position === props.contentPosition;
      })
    );
  });
  const previousLengthsSum = computed(() => {
    return props.contentListLengths.reduce((acc, curr) => {
      acc.push((acc[acc.length - 1] || 0) + curr);
      return acc;
    }, [0]);
  });
</script>
