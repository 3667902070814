<template>
  <nav :class="$style.header">
    <div v-if="breadcrumbs && breadcrumbs.length" :class="$style.headerBreadcrumb">
      <aw-breadcrumb-version-2 :external-breadcrumbs="breadcrumbs" has-tooltip />
    </div>
    <div :class="$style.headerContent">
      <div aria-live="polite" aria-atomic="true" :class="$style.headerContentInfo">
        <slot name="listName" />
        <span v-if="productCounterText" :class="$style.headerContentInfoCounter">
          {{ productCounterText }}
        </span>
      </div>
      <div :class="$style.headerContentFilter">
        <slot name="filters" />
      </div>
    </div>
  </nav>
</template>

<script>
  import { mapState } from 'pinia';
  import AwBreadcrumbVersion2 from '~~/common/components/Common/AwBreadcrumbVersion2.vue';

  import { useUrlResourceStore } from '~~/common/stores/urlResource';
  import { useCategoryStore } from '~~/common/stores/category';

  export default {
    name: 'AwProductListHeader',
    components: {
      AwBreadcrumbVersion2,
    },
    props: {
      productCounter: {
        type: Object,
        default: null,
        validator: counter => counter === null || (typeof counter?.all === 'number' && typeof counter.loaded === 'number'),
      },
      breadcrumbs: {
        type: Array,
        default: null,
      },
    },
    computed: {
      ...mapState(useCategoryStore, {
        categoryName: state => state.category?.name,
      }),
      ...mapState(useUrlResourceStore, {
        resourceType: state => state.data.resourceType,
      }),
      productCounterText () {
        if (!this.productCounter) {
          return null;
        }
        return this.$awt('aw.common.product_list.loaded_products', this.productCounter);
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include tablet(min) {
    gap: 40px;
  }

  &Content {
    display: flex;
    flex-direction: column;
    @include tablet(min) {
      flex-direction: row;
      justify-content: space-between;
    }

    &Info {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &Counter {
        font-family: $secondary-font;
        font-size: 12px;
        font-weight: $font-weight-regular-v2;
        line-height: 16px;
        color: $color-text-secondary;
        @include tablet(min) {
          font-size: 14px;
          font-weight: $font-weight-normal-v2;
          line-height: 20px;
        }
      }
    }

    &Filter {
      align-self: center;
      width: 100%;
      @include tablet(min) {
        width: 255px;
      }
    }
  }
}
</style>
