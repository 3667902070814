<template>
  <div>
    <!-- region Header content -->
    <slot name="content" />
    <!-- endregion Header content -->

    <!-- region Order row -->
    <aw-product-list-order-by-and-mobile-filter-modal
      v-if="screenRange['mobile-max']"
      :is-filterable="isFilterable && !isSearchPage"
      :is-sortable="isSortable"
      has-switcher-icon
      controls-visible-status
    />
    <!-- endregion Order row -->

    <!-- region Active filters -->
    <aw-product-list-active-filters v-if="screenRange['tablet-min'] && isWholeLoadingCompleted" :class="$style.productListActiveFilters" controls-visible-status />
    <!-- endregion Active filters -->

    <aw-cms-content-list
      :content-position="CCP_ABOVE_FIRST_ROW"
      :content-list="contentData"
      :content-list-lengths="contentDataLengths"
      force-full-width
    />

    <!-- region Product list -->
    <div :class="[$style.listContent, $style[`listContent${ isShop ? 'Shop' : 'Dealer' }`]]">
      <div v-if="isFilterable && screenRange['tablet-min']" :class="[$style.filtersSide]">
        <!-- region SUBCATEGORY FILTER -->
        <lv-heading
          tag="h3"
          level="3"
          version2
          :class="$style.filterSectionTitle"
        >
          {{ $awt('aw.product_list.filter.category.title') }}
        </lv-heading>
        <aw-subcategory-filter
          :is-list-loading="!isMoreLoadingCompleted"
          view="FULL"
          :class="$style.subcategoryFilter"
          @activate-filter="$emit('filter-product-items')"
        />
        <aw-category-selection-filter
          v-if="(isBoutique || isShortList || isLoyaltyOffer) && hasCategorySelectionFilters"
          :is-list-loading="!isMoreLoadingCompleted"
          view="FULL"
          :class="$style.subcategoryFilter"
          @activate-filter="$emit('filter-product-items')"
        />
        <!-- endregion SUBCATEGORY FILTER -->

        <!-- region PRODUCT LIST FILTER   -->
        <lv-heading
          tag="h3"
          level="3"
          version2
          :class="$style.filterSectionTitle"
        >
          {{ $awt('aw.product_list.filter.filter.title') }}
        </lv-heading>
        <aw-product-list-filters
          v-if="filters && filters.length"
          @activate-filter="$emit('filter-product-items')"
        />
        <!-- endregion PRODUCT LIST FILTER -->
      </div>
      <div :class="[$style.productsSide]">
        <ul
          :class="['ulReset', $style.productContainer, $style[`productContainer${ isShop ? 'Shop' : 'Dealer' }`]]"
          style="visibility:hidden;height:0;margin-top:0;margin-bottom:0;padding-top:0;padding-bottom:0;border-top:0;border-bottom:0;"
        >
          <li
            id="awProductList_productCardSizeCalculator"
            :class="[
              $style.productContainerItem,
              $style.cardWrapper,
              $style.cardWrapperSINGLE,
            ]"
          />
        </ul>
        <template v-if="products && products.length && !alcoholProductIsLoading">
          <template v-if="isWholeLoadingCompleted">
            <!-- region PRODUCT CARD ITEMS -->
            <slot name="products">
              <h2 id="feedLabel" class="awSrOnly">
                {{ $awt('aw.product_list.accessibility.feed') }}
              </h2>
              <ul
                ref="feed"
                role="feed"
                :aria-busy="(!isMoreLoadingCompleted).toString()"
                aria-labelledby="feedLabel"
                :class="['ulReset', $style.productContainer, $style[`productContainer${ isShop ? 'Shop' : 'Dealer' }`]]"
              >
                <li
                  v-for="(data, index) in renderedListItem"
                  :key="`product-card-${data.id}`"
                  role="article"
                  :aria-posinset="index+1"
                  :aria-labelledby="`articleLabel-${ data.id }`"
                  :aria-setsize="renderedListItem.length"
                  tabindex="0"
                  :class="[
                    $style.productContainerItem,
                    $style.cardWrapper,
                    $style[`cardWrapper${cardPlacement(data.index, data.isCmsPage)}`],
                  ]"
                >
                  <span :id="`articleLabel-${ data.id }`" class="awSrOnly">
                    <template v-if="data.isCmsPage">
                      {{ $awt('aw.product_list.accessibility.banner') }}: {{ data.attributes?.contentList?.map(cnt => cnt.title)?.join(', ') }}
                    </template>
                    <template v-else>
                      {{ $awt('aw.product_list.accessibility.card') }}: {{ getNameByProductData(data.attributes.productData) }}
                    </template>
                  </span>
                  <aw-if-visible :set-width="false" :root-margin="(w) => `${w.innerHeight}px 0px`" :class="[$style.ifVisible, `prodPageReturn-${getIdFromData(data)}`]">
                    <component
                      :is="data.component"
                      v-if="data.isCmsPage || productCardSize"
                      heading-tag="h3"
                      :inline-cms-breakpoints="data.isCmsPage ? inlineCmsBreakpoints : null"
                      v-bind="data.attributes"
                      :list-index="data.index"
                      @show-popup="showPopup = true"
                      @activate-filter="$emit('filter-product-items')"
                    />
                  </aw-if-visible>
                </li>
              </ul>
            </slot>
            <!-- endregion PRODUCT CARD ITEMS -->
          </template>
          <!-- region MORE PRODUCTS LOADER -->
          <client-only>
            <div class="awSrOnlyAbs">
              <h2 v-text="$awt('aw.product_list.paging.title')" />
              <div>
                {{
                  $awt('aw.product_list.more_items_loading_text', {
                    loaded_products_count: renderedProducts.length,
                    all_products_count: itemCount,
                  })
                }}
              </div>
            </div>
            <aw-more-items-version2
              v-if="isWholeLoadingCompleted"
              :loaded-items="renderedProducts.length"
              :sum-items="itemCount"
              :load-completed="isMoreLoadingCompleted"
              :load-more-button-text="$awt('aw.product_list.more_items_btn_text')"
              :class="$style.moreItems"
              @load-more-items="loadMore"
            />
          </client-only>

          <!-- endregion MORE PRODUCTS LOADER -->
        </template>
        <template v-else>
          <aw-spinner v-if="!isWholeLoadingCompleted || !isMoreLoadingCompleted || alcoholProductIsLoading" color="currentColor" :class="$style.spinner" />
          <p v-else :class="[$style.noItemsToken]" aria-live="polite">
            <img :src="abstract77" aria-hidden="true" loading="lazy">
            {{ $awt(noItemsToken) }}
          </p>
        </template>
      </div>
    </div>
    <!-- endregion Product list -->

    <aw-separator margin-bottom="md" margin-top="md" />

    <aw-cms-content-list
      :content-position="CCP_UNDER_PRODUCT_LIST"
      :content-list="contentData"
      :content-list-lengths="contentDataLengths"
      force-full-width
    />
  </div>
</template>

<script>
  import { defineAsyncComponent, nextTick } from 'vue';
  import { mapState } from 'pinia';
  import { LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import abstract77 from '~~/common/assets/images/abstract-77.png';
  import pageInstanceMixin from '~~/common/mixins/pageInstanceMixin.js';
  import pageTypeMixin from '~~/common/mixins/pageTypeMixin.js';
  import { useUrlResourceStore } from '~~/common/stores/urlResource';
  import { useContentsStore } from '~~/common/stores/contents';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useCategoryStore } from '~~/common/stores/category';
  import { useCompareStore } from '~~/common/stores/compare';
  import { useProductsStore } from '~~/common/stores/products';
  import FeedHelper from '~~/common/utils/FeedHelper.js';
  import { contentPageTypePositionsByIndex, getContentDataLengths, getSortedContentDataByPos } from '~~/common/utils/contentData.js';
  import { getProductCardSizeV2 } from '~~/common/utils/productCard.js';
  import AwSeparator from '~~/common/components/Common/AwSeparator';
  import AwCmsContentList from '../Content/AwCmsContentList.vue';
  import {
    CCP_ABOVE_FIRST_ROW,
    CCP_UNDER_FIRST_ROW,
    CCP_UNDER_SECOND_ROW,
    CCP_UNDER_THIRD_ROW,
    CCP_UNDER_PRODUCT_LIST,
  } from '~~/common/config/ContentPageTypePosition.js';
  import { PUSH_VIEW_PRODUCT_LIST } from '~~/common/plugins/aw-analytics.js';

  const cardPlacementOption = {
    SINGLE: 'SINGLE',
    HALF: 'HALF',
    TWO_THIRD: 'TWO_THIRD',
    FULL: 'FULL',
  };
  const AwProductCardVersion2 = defineAsyncComponent(() => import('~~/common/components/Common/AwProductCardVersion2'));

  export default {
    name: 'AwProductList',
    components: {
      AwSeparator,
      LvHeading,
      AwProductCardVersion2,
      AwMoreItemsVersion2: defineAsyncComponent(() => import('~~/common/components/Common/AwMoreItemsVersion2')),
      AwProductListOrderByAndMobileFilterModal: defineAsyncComponent(() => import('~~/common/components/Page/ProductList/AwProductListOrderByAndMobileFilterModal')),
      AwSubcategoryFilter: defineAsyncComponent(() => import('~~/common/components/Page/ProductList/AwSubcategoryFilter.vue')),
      AwCategorySelectionFilter: defineAsyncComponent(() => import('~~/common/components/Page/ProductList/AwCategorySelectionFilter.vue')),
      AwProductListFilters: defineAsyncComponent(() => import('~~/common/components/Page/ProductList/AwProductListFilters')),
      AwProductListActiveFilters: defineAsyncComponent(() => import('~~/common/components/Page/ProductList/AwProductListActiveFilters')),
      AwIfVisible: defineAsyncComponent(() => import('~~/common/components/Common/AwIfVisible')),
      AwSpinner: defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner')),
      AwCmsContentList,
    },
    mixins: [
      pageInstanceMixin,
      pageTypeMixin,
    ],
    props: {
      isWholeLoadingCompleted: {
        type: Boolean,
        default: false,
      },
      isMoreLoadingCompleted: {
        type: Boolean,
        default: true,
      },
      itemCount: {
        type: Number,
        default: 0,
      },
      noItemsToken: {
        type: String,
        default: 'aw.product_list.no_items_token',
      },
      isFilterable: {
        type: Boolean,
        default: true,
      },
      isSortable: {
        type: Boolean,
        default: true,
      },
      isSearch: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'filter-product-items',
      'order-product-items',
      'load-more-product-items',
    ],
    data () {
      return {
        showPopup: false,
        feedHelper: null,
        productCardSize: null,
        abstract77,
        cardPlacementOption,
        CCP_ABOVE_FIRST_ROW,
        CCP_UNDER_PRODUCT_LIST,
      };
    },
    computed: {
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
        screenSize: state => state.mediaQueries.query,
      }),
      ...mapState(useProductsStore, {
        products: state => state.products,
        filters: state => state.filters,
        renderedProducts: state => state.renderedProducts,
        alcoholProductIsLoading: state => state.alcoholProductIsLoading,
        highlightedProductCount: state => state.highlightedProductCount,
        hasCategorySelectionFilters: state => !!state.getCategorySelectionFilter?.values?.length,
        searchText: state => state.searchText,
      }),
      ...mapState(useCategoryStore, {
        hasCompare: state => Boolean(state.category?.comparable),
        categoryName: state => state.category?.name,
      }),
      ...mapState(useCompareStore, {
        compareItems: state => state.compareItems,
      }),
      ...mapState(useUrlResourceStore, {
        urlResource: state => state.data,
      }),
      ...mapState(useContentsStore, {
        contentData: (state) => {
          return getSortedContentDataByPos(state.dataByResourceId, contentPageTypePositionsByIndex);
        },
      }),
      contentDataLengths () {
        return getContentDataLengths(this.contentData);
      },
      hasContentData () {
        if (!this.contentData) {
          return;
        }
        const result = {};
        this.contentData?.forEach((content) => {
          result[content.position] = true;
        });
        return result || {};
      },
      inlineCmsBreakpoints () {
        return [
          {
            at: this.screenRange['desktop-large-min'],
            count: 4,
            columnGap: '20px',
          },
          {
            at: this.screenRange['desktop-small-min'],
            count: 3,
            columnGap: '20px',
          },
          {
            at: this.screenRange['tablet-min'],
            count: 2,
            columnGap: '20px',
          },
          {
            at: true,
            count: 'auto',
            columnGap: '8px',
          },
        ];
      },
      renderedListItem () {
        const products = this.renderedProducts.map((data, index) => ({
          component: AwProductCardVersion2,
          id: data.id,
          index,
          isCmsPage: false,
          attributes: {
            productData: data,
            hasCompare: this.hasCompare,
            class: this.$style.productCard,
            cardSize: this.productCardSize,
            openedDetails: 'auto',
          },
        }));

        const insertContentPositions = Object.keys(this.hasContentData).filter(position => ![CCP_UNDER_PRODUCT_LIST, CCP_ABOVE_FIRST_ROW].includes(position));

        let resultList = products;
        if (insertContentPositions.length) {
          for (const index in insertContentPositions) {
            const position = insertContentPositions[index];
            const insertIndex = this.getInsertListIndexByContentPosition(position);
            const cmsPageData = {
              component: AwCmsContentList,
              id: `cms_${index}`,
              isCmsPage: true,
              attributes: {
                contentList: this.contentData,
                contentListLengths: this.contentDataLengths,
                forceFullWidth: true,
                contentPosition: position,
                class: this.$style.cmsPage,
              },
            };
            resultList = [...resultList.slice(0, insertIndex), cmsPageData, ...resultList.slice(insertIndex)];
          }
          return resultList;
        } else {
          return products;
        }
      },
    },
    watch: {
      products (newVal) {
        if (newVal.length && !this.feedHelper) {
          nextTick(() => {
            nextTick(() => {
              if (this.$refs?.feed) {
                this.feedHelper = new FeedHelper(this.$refs.feed);
              }
            });
          });
        }
      },
      itemCount (newVal, oldVal) {
        if (newVal >= oldVal) {
          const loadingComponent = this.$refs.infiniteLoading;
          if (loadingComponent) {
            loadingComponent.stateChanger.reset();
          }
        }
      },
    },
    created () {
      this.$awEvents.addEvent('activate-filter', this.emitFilterProductItems);
      this.$awEvents.addEvent('sort-data', this.emitOrderProductItems);
    },
    mounted () {
      if (!this.isSearch) {
        this.$awAnalytics[PUSH_VIEW_PRODUCT_LIST]({
          categoryName: this.categoryName,
          products: this.products,
        });
      }
      this.$awEvents.addEvent('throttled-resize', this.setCardSize);
      this.setCardSize();
      const productsStore = useProductsStore();
      const id = this.urlResource?.resourceId;
      if (id && this.urlResource?.resourceType === 'search') {
        productsStore.setSearchText(Array.from(new URLSearchParams(id).values()));
      }
      // if contain compare store any product, then show compare popup
      if (this.compareItems.length) {
        this.showPopup = true;
      }
      productsStore.setupProductList();
    },
    beforeUnmount () {
      this.$awEvents.removeEvent('activate-filter', this.emitFilterProductItems);
      this.$awEvents.removeEvent('sort-data', this.emitOrderProductItems);
      this.feedHelper && this.feedHelper.destroy();
      useContentsStore().clearDataByResourceId();
    },
    unmounted () {
      clearTimeout(this.setCardSizeTimeoutId);
      this.$awEvents.removeEvent('throttled-resize', this.setCardSize);
    },
    methods: {
      getInsertListIndexByContentPosition (contentPosition) {
        const productsStore = useProductsStore();

        const itemsPerPage = productsStore.itemsPerPage;
        const highlightedProductCount = productsStore.highlightedProductCount;
        const adjustToDealerLayout = !this.isShop && (itemsPerPage === 16 || itemsPerPage === 12);
        let insertIndex = 0;

        switch (contentPosition) {
        case CCP_UNDER_FIRST_ROW:
          insertIndex = itemsPerPage / 4;
          if (highlightedProductCount >= 2) {
            if (itemsPerPage === 12 || itemsPerPage === 8) {
              insertIndex = insertIndex - 1;
            } else {
              insertIndex = insertIndex - 2;
            }
          }
          if (adjustToDealerLayout) {
            insertIndex = insertIndex + 1;
          }
          break;
        case CCP_UNDER_SECOND_ROW:
          insertIndex = itemsPerPage / 4 * 2;

          if (highlightedProductCount === 2) {
            if (itemsPerPage === 12) {
              insertIndex = insertIndex - 1;
            } else {
              insertIndex = insertIndex - 2;
            }
          } else if (highlightedProductCount === 3) {
            if (itemsPerPage === 8 || itemsPerPage === 12) {
              insertIndex = insertIndex - 2;
            } else {
              insertIndex = insertIndex - 3;
            }
          } else if (highlightedProductCount >= 4) {
            if (itemsPerPage === 8 || itemsPerPage === 12) {
              insertIndex = insertIndex - 2;
            } else {
              insertIndex = insertIndex - 4;
            }
          }
          if (adjustToDealerLayout) {
            insertIndex = insertIndex + 2;
          }
          break;
        case CCP_UNDER_THIRD_ROW:
          insertIndex = itemsPerPage / 4 * 3;

          if (highlightedProductCount === 2) {
            if (itemsPerPage === 12) {
              insertIndex = insertIndex - 1;
            } else {
              insertIndex = insertIndex - 2;
            }
          } else if (highlightedProductCount === 3) {
            if (itemsPerPage === 8 || itemsPerPage === 12) {
              insertIndex = insertIndex - 2;
            } else {
              insertIndex = insertIndex - 3;
            }
          } else if (highlightedProductCount === 4) {
            if (itemsPerPage === 8 || itemsPerPage === 12) {
              insertIndex = insertIndex - 2;
            } else {
              insertIndex = insertIndex - 4;
            }
          } else if (highlightedProductCount === 5) {
            if (itemsPerPage === 8 || itemsPerPage === 12) {
              insertIndex = insertIndex - 3;
            } else {
              insertIndex = insertIndex - 5;
            }
          } else if (highlightedProductCount >= 6) {
            if (itemsPerPage === 8 || itemsPerPage === 12) {
              insertIndex = insertIndex - 3;
            } else {
              insertIndex = insertIndex - 6;
            }
          }
          if (adjustToDealerLayout) {
            insertIndex = insertIndex + 3;
          }
          break;
        default:
          insertIndex = 0;
          break;
        }

        if (highlightedProductCount === 1) {
          insertIndex = insertIndex - highlightedProductCount;
        }

        return insertIndex;
      },
      getIdFromData (data) {
        return data?.attributes?.productData?.id || data?.attributes?.productData?.selectedVariant?.id;
      },
      setCardSize () {
        const clientWidth = document.getElementById('awProductList_productCardSizeCalculator')?.clientWidth;
        const pcs = getProductCardSizeV2(clientWidth);
        clearTimeout(this.setCardSizeTimeoutId);
        delete this.setCardSizeTimeoutId;
        if (pcs) {
          this.productCardSize = pcs;
        } else if (!this.productCardSize) {
          this.setCardSizeTimeoutId = setTimeout(() => {
            delete this.setCardSizeTimeoutId;
            this.$logger.error('This is fallback mechanism sets cardSize if we failed to do it for any reason. If this is triggered we did something wrong.');
            this.setCardSize();
          }, 5000);
        }
      },
      emitFilterProductItems (event) {
        this.$emit('filter-product-items', event);
      },
      emitOrderProductItems (event) {
        this.$emit('order-product-items', event);
      },
      isHighlighted (productData) {
        return !!productData?.highlightedParameters?.length || false;
      },
      cardPlacement (index, isCmsPage) {
        if (isCmsPage) {
          return this.cardPlacementOption.FULL;
        }
        const card = this.products[index];
        if (!this.isHighlighted(card)) {
          return this.cardPlacementOption.SINGLE;
        }
        const highlightedProductCount = this.highlightedProductCount;
        if (highlightedProductCount % 2 === 0) {
          return this.cardPlacementOption.HALF;
        }
        const nextCard = this.products?.[index + 1];
        if (!nextCard) {
          return this.cardPlacementOption.HALF;
        }
        return this.isHighlighted(nextCard) ? this.cardPlacementOption.HALF : this.cardPlacementOption.TWO_THIRD;
      },
      getNameByProductData (productData) {
        return productData?.selectedVariant?.name;
      },
      loadMore (state) {
        if (this.unsubLoadMoreFinished) {
          this.unsubLoadMoreFinished();
        }
        this.unsubLoadMoreFinished = this.$watch('renderedListItem.length', (newVal, oldVal) => {
          this.unsubLoadMoreFinished();
          if (newVal !== oldVal) {
            this.$refs?.feed?.childNodes?.[oldVal]?.focus?.();
          }
        });
        this.$emit('load-more-product-items', state);
      },

    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
// .page {

.productListActiveFilters {
  margin-top: 20px;
}

.listContent {
  display: grid;
  margin-top: 40px;
  gap: 40px;

  &Shop {
    grid-template-columns: 1fr;
    @include tablet(min) {
      grid-template-columns: 237px auto;
    }
    @include desktop-small(min) {
      grid-template-columns: 202px auto;
    }
    @include desktop-medium(min) {
      grid-template-columns: 256px auto;
    }
  }

  &Dealer {
    grid-template-columns: 1fr;
    @include tablet(min) {
      grid-template-columns: minmax(0, 2fr) minmax(0, 10fr);
    }
  }

  .subcategoryFilter {
    margin-bottom: 40px;
  }

  .filterSectionTitle {
    margin-bottom: 20px;
  }

  .productsSide {
    flex: 1;
    width: 100%;
    min-height: 600px;

    .spinner {
      color: $color-text-primary;
    }

    .noItemsToken {
      font-family: $secondary-font;
      font-size: 20px;
      font-weight: $font-weight-medium-v2;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: $color-text-secondary;
    }

    .productContainer {
      display: grid;
      padding: 0;
      gap: 8px;

      @include tablet(min) {
        gap: 20px;
      }

      &Shop {
        grid-template-columns: repeat(2, 1fr);

        @include desktop-small(min) {
          grid-template-columns: repeat(6, 1fr);
        }
        @include desktop-large(min) {
          grid-template-columns: repeat(4, 1fr);
        }

        .cardWrapper {
          &SINGLE {
            grid-column-end: span 1;
            @include desktop-small(min) {
              grid-column-end: span 2;
            }
            @include desktop-large(min) {
              grid-column-end: span 1;
            }
          }

          &HALF {
            grid-column-end: span 2;
            @include desktop-small(min) {
              grid-column-end: span 3;
            }
            @include desktop-large(min) {
              grid-column-end: span 2;
            }
          }

          &TWO_THIRD {
            grid-column-end: span 2;
            @include desktop-small(min) {
              grid-column-end: span 4;
            }
            @include desktop-large(min) {
              grid-column-end: span 2;
            }
          }

          &FULL {
            grid-column-end: span 2;
            @include desktop-small(min) {
              grid-column-end: span 6;
            }
            @include desktop-large(min) {
              grid-column-end: span 4;
            }
          }
        }
      }

      &Dealer {
        grid-template-columns: repeat(2, 1fr);

        @include desktop-small(min) {
          grid-template-columns: repeat(3, 1fr);
        }
        @include desktop-medium(min) {
          grid-template-columns: repeat(4, 1fr);
        }
        @include desktop-large(min) {
          grid-template-columns: repeat(5, 1fr);
        }

        .cardWrapper {
          &FULL {
            grid-column-end: span 2;
            @include desktop-small(min) {
              grid-column-end: span 3;
            }
            @include desktop-medium(min) {
              grid-column-end: span 4;
            }
            @include desktop-large(min) {
              grid-column-end: span 5;
            }
          }
        }
      }

      &Item {
        display: flex;
        flex-direction: column;

        .ifVisible {
          display: flex;
          flex: 1;

          .productCard,
          .cmsPage {
            flex: 1;
          }

          &:empty {
            min-height: 669px;
            border-radius: $product-card-radius;
            background: $product-card-bg-primary-color;
          }
        }
      }
    }
  }
}

// }
.moreItems {
  margin-top: 40px;
}
</style>
